export function parseDate(date) {
  var parts = date.split('-');
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function getDate() {
  let date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  if (month < 10) return `${year}-0${month}-${day}`;
  return `${year}-${month}-${day}`;
}
