import { navigate } from 'gatsby';
import { useWindowDimensions } from '../../../hooks/useDimension';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import s from './index.module.scss';
import validation from '../../../utils/validations';
import { parseDate, getDate } from '../../../utils/dates';
import step2 from '../../../../static/steps/step2.png';

//Components
import RadioInput from '../../../components/radioInput/index';
import Input from '../../../components/input/index';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import Button from '../../../components/button/index';
import Seo from '../../../components/Seo';

import { checkUser } from '../../../api/auth';
import { checkReservation, createReservation } from '../../../api/reservations';

const SelectDate = ({ location }) => {
  const { width, height } = useWindowDimensions();
  const { register, handleSubmit, errors, watch } = useForm();

  const watchPeople = watch('people', 15);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({
    status: false,
    message: '',
  });

  const [radioButtonTrue, setRadioButtonTrue] = useState(false);
  const [radioButtonFalse, setRadioButtonFalse] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  useEffect(() => {
    if (!location.state && !location.state?.experience)
      return navigate('/experiences');
  }, [location]);

  const onSubmit = async ({ date, transport, people }) => {
    try {
      if (parseDate(date) <= parseDate(getDate())) {
        setDateErr(true);
        return;
      }

      setLoading(true);

      const user = await checkUser();
      if (!user) {
        setLoading(false);
        return navigate('/login', {
          state: {
            previous: '/experiences/date',
            experience: location.state.experience,
          },
        });
      }

      const experience = location.state.experience;
      const userId = user.uid;

      console.log(date, transport, people);

      // chequeamos disponibilidad de fecha (sin contar reservas sin pagar)
      const check = await checkReservation(experience.id, date);
      console.log(check);

      if (check.length) {
        setErr({
          ...err,
          status: true,
          message: 'Fecha no disponible',
        });

        setLoading(false);
        return;
      }

      // seteamos precio total
      let totalPrice = experience.price;

      if (transport && people === 15) totalPrice += 3; //9500
      if (transport && people === 20) totalPrice += 3; //8500
      if (transport && people === 25) totalPrice += 1; //7600

      // creamos una nueva reserva
      const newReservation = await createReservation({
        day: date,
        people: people,
        userId: userId,
        total: totalPrice,
        experienceId: experience.id,
        transport: transport === 'true' ? true : false,
      });

      setLoading(false);
      navigate('/experiences/pay', {
        state: {
          newReservation,
        },
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const transportTrue = () => {
    document.getElementById('transportTrue').checked = true;
    document.getElementById('transportFalse').checked = false;
    setRadioButtonTrue(true);
    setRadioButtonFalse(false);
  };

  const transportFalse = () => {
    document.getElementById('transportTrue').checked = false;
    document.getElementById('transportFalse').checked = true;
    setRadioButtonTrue(false);
    setRadioButtonFalse(true);
  };
  return (
    <Fragment>
      <Seo
        lang="es"
        description="Selecciona la fecha y el número de amigos que asistirán"
        title="Fecha y número de amigos"
      />

      <Header step={step2} />
      <div className={s.container}>
        <div className={s.box}>
          <div className={s.content}>
            <form className={s.selectForm} onSubmit={handleSubmit(onSubmit)}>
              <p className={s.title1}>corona</p>
              <p className={`${s.title2} ${s.yellow}`}>mountain sessions</p>
              <p className={s.title3}> CAJON DEL MAIPO</p>
              <div className={s.dateAndPeople}>
                <div className={s.dateDiv}>
                  <p className={s.text}>Elige una fecha:</p>
                  <Input
                    width="80%"
                    type="date"
                    name="date"
                    err={errors.date}
                    className="dateInput"
                    referencia={register({
                      valueAsDate: false,
                      required: 'Ingrese una fecha válida',
                    })}
                  />
                  {dateErr ? (
                    <p className={s.dateErr}>Ingrese una fecha válida</p>
                  ) : null}
                </div>

                <div className={s.peopleDiv}>
                  <p className={s.text}>¿Cuántas personas asistirán?</p>

                  <select
                    className={s.numberInput}
                    ref={register(validation.required)}
                    name="people"
                  >
                    <option value="15" className={s.selectNumber}>
                      15
                    </option>
                    <option value="20" className={s.selectNumber}>
                      20
                    </option>
                    <option value="25" className={s.selectNumber}>
                      25
                    </option>
                  </select>
                </div>
              </div>
              <div className={s.priceAndButton}>
                <p className={s.price}>
                  $25.990 <span className={s.miniText}>x persona</span>
                </p>

                <Button
                  type="submit"
                  className="selectDate"
                  value="Agregar al carrito"
                  loading={loading}
                />
              </div>
              <p className={s.text}>
                ¿Necesitas una van para ti y tus amigos en un punto de
                encuentro?
              </p>

              <div className={s.selectVan}>
                {watchPeople == 15 && <p>15 personas = $9.500 x persona</p>}
                {watchPeople == 20 && <p>20 personas = $8.500 x persona</p>}
                {watchPeople == 25 && <p>25 personas = $7.600 x persona</p>}

                <div className={s.select}>
                  <div className={s.selectContainer} onClick={transportTrue}>
                    <div
                      className={
                        radioButtonTrue ? s.checkboxTrue : s.checkboxFalse
                      }
                    ></div>
                    <p>Si</p>
                    <RadioInput
                      id="transportTrue"
                      name="transport"
                      className="radio"
                      value={true}
                      referencia={register}
                      type={'radio'}
                      styles={{ opacity: '0' }}
                    />
                  </div>
                  <div className={s.selectContainer} onClick={transportFalse}>
                    <div
                      className={
                        radioButtonFalse ? s.checkboxTrue : s.checkboxFalse
                      }
                    ></div>
                    <p>No</p>
                    <RadioInput
                      id="transportFalse"
                      name="transport"
                      className="radio"
                      value={false}
                      referencia={register}
                      type={'radio'}
                      styles={{ opacity: '0' }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Footer
          styles={{
            position: width <= 768 || height <= 600 ? 'static' : 'absolute',
          }}
        />
      </div>
    </Fragment>
  );
};

export default SelectDate;
